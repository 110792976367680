export class TextReveal extends HTMLElement {
    constructor(args = {text: '', truncateValue: '30', block: false, ctaText: 'Show', truncateBy: 'words'}) {
      super();

      this.text = this.getAttribute('text') || args.text;
      this.truncateValue = parseInt(this.getAttribute('word-limit')) || parseInt(args.truncateValue);
      this.truncateBy = this.getAttribute('truncate-by') || args.truncateBy;
      this.block = this.hasAttribute('block') || args.block;
      this.ctaText = this.getAttribute('cta-text') || args.ctaText;

      this.paragraph = document.createElement('div');
      this.truncateText = this.text.split(" ").length > this.truncateValue;

      this.truncateText ? this.generateTruncatedHTML() : this.paragraph.innerHTML = this.text;
      this.appendChild(this.paragraph);
    }

    generateTruncatedHTML = () => {
        let textHTML, truncatedHTML;
    
        if (this.truncateBy === 'words') {
            const allWords = this.text.split(" ");
            const textWords = allWords.slice(0, this.truncateValue);
            truncatedHTML = allWords.slice(this.truncateValue).join(" ");
            textHTML = textWords.join(" ")+ "&nbsp;";
        } else {
            const allParagraphs = this.text.split("</p>");
            
            // Only consider it as truncatable if there's more than one paragraph
            if(allParagraphs.length > 1) {
                textHTML = allParagraphs[0] + "</p>";
                truncatedHTML = allParagraphs.slice(1).join("</p>");
                if (truncatedHTML.startsWith("<p>")) {
                    truncatedHTML = truncatedHTML.substring(3);
                }
            } else {
                textHTML = this.text;
                truncatedHTML = "";
            }
        }
    
        this.truncatedElement = document.createElement('span');
        this.truncatedElement.innerHTML = truncatedHTML;
        this.truncatedElement.setAttribute("class", 'truncated-text')
    
        this.paragraph.innerHTML = textHTML;
        this.paragraph.appendChild(this.truncatedElement);
    
        // Only add the button if there's content to truncate
        if (truncatedHTML.length > 0) {
            this.paragraph.appendChild(this.createButton());
        }
    }
    

    createButton = () => {
        this.button = document.createElement("span");

        if(this.block){
            this.button.classList.add("read-more-button", "block", 'mt-4', 'ml-0');
        } else {
            this.button.classList.add("read-more-button");
        }
        this.button.innerText = this.ctaText + " More";
        this.button.addEventListener("click", this.toggleText);

        return this.button;
    }

    toggleText = () => {
        this.truncatedElement.classList.toggle('truncated-active');
        this.button.textContent === this.ctaText + " More" ? this.button.innerText = this.ctaText + " Less" : this.button.innerText = this.ctaText + " More";
        if(!this.block){
            this.button.classList.toggle('block');
            this.button.classList.toggle('mt-4');
        }
    }
}

customElements.define('text-reveal', TextReveal);